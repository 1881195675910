const useColor = () => ({
  primary: '#0075FF',
  white: '#FFFFFF',
  white200: '#F1F1F5',
  white300: '#E1E2E3',
  dark: '#101319',
  blue200: '#A1CCFF',
  blue800: '#152338',
  darkblue: '#195BDC',
  lowDark: '#171A22',
  grey100: '#ACADB0',
  grey: '#898A8E',
  grey500: '#8B8C91',
  green500: '#08BD50',
  grey600: '#2E3138',
  red500: '#FA3E2C',
});

export default useColor;
